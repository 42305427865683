/* eslint-disable camelcase */
import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Typography } from '@refera/ui-web'
import { Message, ArrowLeft as ArrowLeftIcon, FilterSearch as FilterIcon } from '@refera/ui-icons'
import Theme from '@refera/ui-core'
import { Grid } from '@material-ui/core'
import { navigate } from '@reach/router'

// Actions
import { getMessaging, getSetItems } from '_modules/mensageria/actions'
import { getStepStatusOptions } from '_/modules/service-orders/actions'
import { getAllRoleGroups } from '_/modules/utils/actions'
import { getBusinessFronts } from '_/modules/businessFront/actions'
import { getAgencies, getAgenciesNextPage } from '_/modules/agency/actions'

// Selectors
import { getUserLoadingSelector } from '_modules/authentication/selectors'
import { getMessageSelector } from '_modules/mensageria/selector'

// Internal components
import Loading from '_components/loading'
import IconButton from '_/components/svg/icon-button'
import { MessageMainTable } from '_components/mensageria'

// Others
import useStyles from './styles'
import { ROUTES } from '_utils/constants'
import { useToast } from '_/hooks/use-toast'
import { MessageFilterDrawer } from './filters'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import useRolePermission from '_/hooks/use-role-permission'

const Mensageria = () => {
  const styles = useStyles()
  const { checkIfUserDoesNotPermission } = useRolePermission()

  const dispatch = useDispatch()
  const { showToast } = useToast()

  const loading = useSelector(getUserLoadingSelector)
  const messageList = useSelector(getMessageSelector)

  const [stateList, setStateList] = useState(messageList?.results || [])
  const [isGridLoading, setGridIsLoading] = useState(false)

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [orderBy, setOrderBy] = useState('')

  const [applyedfilters, setApplyedFilters] = useState({})
  const [filters, setFilters] = useState(0)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const loadNextPage = async () => {
    await dispatch(getAgenciesNextPage()).then(async ({ hasNext }) => {
      if (hasNext) await loadNextPage()
    })
  }

  const handleFilters = () => setDrawerOpen(prevState => !prevState)

  const handleRowClick = useCallback(() => {
    if (checkIfUserDoesNotPermission(PERMISSIONS.MESSENGER, PERMISSIONS_ACTIONS.ADD)) {
      return
    }

    navigate(`${ROUTES.MENSAGERIA}/novo`, 'noopener,noreferrer')
  }, [])

  const handleOrderBy = useCallback(
    orderObj => {
      const order = orderObj[0]
      if (!order) {
        handleOrderBy('id')
        return
      }
      const { field, sort } = order
      sort === 'desc' ? setOrderBy(`-${field}`) : setOrderBy(field)
    },
    [setOrderBy]
  )

  const handleGetMessage = useCallback(() => {
    setGridIsLoading(true)

    return dispatch(getMessaging({ pageSize, page: page + 1, orderBy, ...filters }))
      .then(data => {
        setStateList(data?.results || [])
        return setGridIsLoading(false)
      })
      .catch(() => {
        showToast({ type: 'error', message: 'Ocorreu um erro ao buscar os registros.' })
        return setGridIsLoading(false)
      })
  }, [dispatch, pageSize, page, orderBy, filters])

  const handleFilterMessage = useCallback(
    payload => {
      setGridIsLoading(true)
      const { business_front, agencies } = payload

      const formattedPayload = {
        ...payload,
        agencies: agencies.map(item => item?.id),
        business_front: business_front.map(item => item?.id),
      }

      const filterPayload = {
        ...payload,
        agencies: agencies.map(item => ({ id: item?.id, name: item?.name })),
        business_front: business_front.map(item => ({
          id: item?.id,
          description: item?.description,
        })),
      }

      setApplyedFilters(filterPayload)
      setFilters(formattedPayload)

      return dispatch(getMessaging({ pageSize, page: page + 1, orderBy, ...formattedPayload }))
        .then(data => {
          setStateList(data?.results || [])
          setDrawerOpen(false)
          setPage(0)
          return setGridIsLoading(false)
        })
        .catch(() => {
          showToast({ type: 'error', message: 'Ocorreu um erro ao buscar os registros.' })
          return setGridIsLoading(false)
        })
    },
    [dispatch, pageSize, page, orderBy]
  )

  useEffect(() => {
    handleGetMessage()
  }, [dispatch, pageSize, page, orderBy, filters])

  useEffect(() => {
    dispatch(getMessaging())
      .then(() => {})
      .catch(err =>
        showToast({ type: 'error', message: err?.error_message ?? 'Ocorreu um erro desconhecido' })
      )
  }, [dispatch])

  useEffect(() => {
    dispatch(getStepStatusOptions())
    dispatch(getAllRoleGroups())
    dispatch(getBusinessFronts())
    dispatch(getSetItems())
    dispatch(getAgencies()).then(async ({ hasNext }) => {
      if (hasNext) await loadNextPage()
    })
  }, [])

  const filterIconColor = useMemo(() => {
    return loading ? Theme.Colors.Grayscale.ThirtyTwo : Theme.Colors.Primary.Base
  }, [loading])

  if (loading) {
    return <Loading />
  }

  return (
    <Grid className={styles.container}>
      <Grid className={styles.header}>
        <Grid className={styles.wrapper}>
          <IconButton aria-label="Voltar página" onClick={() => navigate(-1)}>
            <ArrowLeftIcon color="black" />
          </IconButton>
          <Typography variant="h4" component="h1" className={styles.title}>
            Mensageria da plataforma
          </Typography>
        </Grid>
        <Grid className={styles.buttonHeader}>
          <Button
            variant="ghost"
            startIcon={<Message color={Theme.Colors.Primary.Base} />}
            onClick={handleRowClick}
          >
            Nova Mensagem
          </Button>
          <Button
            variant="ghost"
            startIcon={<FilterIcon color={filterIconColor} />}
            onClick={handleFilters}
            disabled={loading}
          >
            Filtros
          </Button>
        </Grid>
      </Grid>
      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <MessageMainTable
            stateList={stateList}
            isLoading={isGridLoading}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            handleOrderBy={handleOrderBy}
          />
        </Grid>
      </Grid>
      <MessageFilterDrawer
        filters={applyedfilters}
        open={drawerOpen}
        close={() => setDrawerOpen(false)}
        applyFilters={handleFilterMessage}
        setFilters={setFilters}
      />
    </Grid>
  )
}

export default Mensageria
